import React, { useState } from 'react';
import Button from 'src/components/10-atoms/button/Button';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import SelectInputGroup from '../select-input-group/SelectInputGroup';
import { ISelectionType } from './types';
const SelectInputDrawer = ({ title, type, onCancel, value, onChange, options, }) => {
    const [selectionValue, setSelectionValue] = useState(value);
    const handleChange = (newValue) => {
        if (type === ISelectionType.SINGLE) {
            onChange(newValue);
        }
        else {
            setSelectionValue(newValue);
        }
    };
    const handleConfirm = () => {
        onChange(selectionValue);
    };
    const renderActions = () => {
        if (type === ISelectionType.SINGLE) {
            return (React.createElement(BottomDrawerLayout.Actions, { xAxis: "center", yAxis: "center" },
                React.createElement(Button, { variant: "text", onPress: onCancel }, "Cancel")));
        }
        else {
            return (React.createElement(BottomDrawerLayout.Actions, null,
                React.createElement(Button, { variant: "text", onPress: onCancel }, "Cancel"),
                React.createElement(Button, { onPress: handleConfirm }, "Confirm")));
        }
    };
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, title),
        React.createElement(BottomDrawerLayout.Body, { ph: 0, pt: 0 },
            React.createElement(SelectInputGroup, { type: type, value: selectionValue, onChange: handleChange, options: options })),
        renderActions()));
};
export default SelectInputDrawer;
