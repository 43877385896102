import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import { interpolateColor } from 'react-native-reanimated';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import Typography from '../typography/Typography';
const CircularProgressBar = ({ ...props }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const color = interpolateColor(props.value, [30, 50, 80], [theme.palette.negative.main, 'orange', theme.palette.positive.main]);
    return (React.createElement(Box, { sx: { position: 'relative', display: 'inline-flex' } },
        React.createElement(CircularProgress, { variant: "determinate", sx: { color }, ...props, value: Math.max(1, props.value) }),
        React.createElement(Box, { sx: {
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            } },
            React.createElement(Typography, { variant: TypographyVariant.BADGE, color: theme.palette.typography.primary }, `${Math.round(props.value)}%`))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default CircularProgressBar;
