import React, { useEffect } from 'react';
import { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import Row, { AnimatedRow } from 'src/components/10-atoms/layout/Row';
import { PRESSABLE_SIZE } from 'src/components/10-atoms/pressables/constants';
import PressableArea from 'src/components/templates/PressableArea';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
const ToggleIcon = ({ status, animationDuration, onToggle }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    const rotation = useSharedValue(0);
    const iconRotateStyle = useAnimatedStyle(() => ({
        transform: [{
                rotateZ: `${rotation.value}deg`
            }]
    }), [rotation]);
    useEffect(() => {
        rotation.value = withTiming(status === 'visible' ? 0 : 180, {
            duration: animationDuration,
            easing: Easing.inOut(Easing.ease),
        });
    }, [status]);
    return (React.createElement(Row, { style: styles.toggleIcon },
        React.createElement(PressableArea, { onPress: onToggle, style: styles.toggleIconOuterShell },
            React.createElement(AnimatedRow, { yAxis: "center", xAxis: "center", style: [iconRotateStyle, styles.toggleIconInnerShell] },
                React.createElement(FeatherIcon, { name: 'chevron-down' })))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    toggleIconOuterShell: {
        width: PRESSABLE_SIZE * 1.5,
        height: PRESSABLE_SIZE * 1.5,
        borderRadius: PRESSABLE_SIZE * 1.5,
        backgroundColor: theme.palette.background.darker,
        alignItems: 'flex-start',
    },
    toggleIconInnerShell: {
        width: PRESSABLE_SIZE,
        height: PRESSABLE_SIZE,
        borderRadius: PRESSABLE_SIZE,
    },
    toggleIcon: {
        position: 'absolute',
        top: -PRESSABLE_SIZE * 0.5,
        left: '50%',
    }
}));
export default ToggleIcon;
