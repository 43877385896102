import { objectParamsConfig } from 'src/navigation/linking/utils';
export const examPreparationStackLinking = {
    screens: {
        EP_Subject: {
            path: 'exam-prep/subject/:subjectID',
            ...objectParamsConfig(['examLevel', 'subject', 'filters']),
        },
        EP_QP: {
            path: 'exam-prep/qp/:qpID',
            ...objectParamsConfig(['examLevel', 'subject', 'qp']),
        },
        EP_Question: {
            path: 'exam-prep/question/:questionID',
            ...objectParamsConfig([
                'examLevel',
                'subject',
                'question',
                'qp',
                'navigationData',
            ]),
        },
        EP_TopicQuestions: {
            path: 'exam-prep/topic-questions/:ancestorID/:topic',
            ...objectParamsConfig(['examLevel', 'subject']),
        },
        EP_AnswerHistory: {
            path: 'exam-prep/answer-history/:questionID',
        },
        EP_QuestionSearch: {
            path: 'exam-prep/question-search',
        },
    },
};
export const examPreparationTabsLinking = {
    screens: {
        EP_Home: {
            path: 'exam-prep/*',
        },
    },
};
