import React from 'react';
import Column from 'src/components/10-atoms/layout/Column';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import QuestionList from 'src/modules/exam-preparation/containers/QuestionList';
import styleUtils from 'src/utils/styles';
import QuestionBankFilters from './Filters';
import useData from './useData';
import SearchInput from 'src/components/10-atoms/form/inputs/SearchInput';
import { PRESSABLE_SIZE } from 'src/components/templates/PressableArea';
const QuestionBankTab = ({ subjectID, onQuestionPress }) => {
    const [{ deviceType, theme }] = useTheme(stylesGenerator);
    const [state, actions] = useData(subjectID);
    return (React.createElement(Column, { style: [styleUtils.ovfHidden, styleUtils.flex] },
        React.createElement(QuestionList, { variant: 'question-paper', queryState: state.listQuery, queryActions: actions.listQuery, variables: state.listQueryVars, onQuestionPress: onQuestionPress, forceCollapse: deviceType.isPhone, renderCollapsibleHeader: () => (React.createElement(Column, null,
                React.createElement(SearchInput, { containerStyle: { paddingHorizontal: theme.spacing.page, paddingTop: theme.spacing.standard.xs, backgroundColor: theme.palette.background.dark }, style: { backgroundColor: theme.palette.background.main }, placeholder: 'Search for questions...', value: state.search.value, onChange: actions.search.change, onClear: () => actions.search.change('') }),
                React.createElement(QuestionBankFilters, { filters: state.filters, onChange: actions.setFilters }))), collapsibleHeaderHeight: (theme.pressableSize.sm + 2 * theme.spacing.standard.xs)
                + (PRESSABLE_SIZE + 2 * theme.spacing.standard.xs) })));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QuestionBankTab;
