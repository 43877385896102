import React, { useEffect, useRef, useState } from 'react';
import { FlatList } from 'react-native-gesture-handler';
import { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import ImageThumbnail from 'src/components/10-atoms/form/inputs/FileInput/ImageThumbnail';
import { THUMBNAIL_SIZE } from 'src/components/10-atoms/form/inputs/FileInput/ThumbnailLayout';
import Row, { AnimatedRow } from 'src/components/10-atoms/layout/Row';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
import ToggleIcon from './ToggleIcon';
import { Platform } from 'react-native';
const ANIMATION_DURATION = 250;
const ImageThumbnailList = ({ index, images, onThumbnailPress }) => {
    const [{ dimensions, theme, styles }] = useTheme(stylesGenerator);
    const flatlistRef = useRef(null);
    useEffectWithCtx((ctx) => {
        flatlistRef.current?.scrollToIndex({
            animated: true,
            index,
            viewOffset: dimensions.viewPort.width / 2 - THUMBNAIL_SIZE / 2
        });
    }, {}, [index]);
    /**
     * Y Position when the Thumbnail is hidden
     *  = THUMBNAIL_SIZE + padding_bottom - offset (so that users know something is hidden below)
     */
    const HIDDEN_YPOS = THUMBNAIL_SIZE + theme.spacing.standard.xs - theme.spacing.standard.xxs;
    const [isVisible, setIsVisible] = useState(true);
    const toggleVisible = () => {
        setIsVisible(prevState => !prevState);
    };
    const yPos = useSharedValue(0);
    const yPosStyle = useAnimatedStyle(() => ({
        transform: [{
                translateY: yPos.value,
            }]
    }), [yPos]);
    useEffect(() => {
        yPos.value = withTiming(isVisible ? 0 : HIDDEN_YPOS, {
            duration: 250,
            easing: Easing.inOut(Easing.ease),
        });
    }, [isVisible]);
    return (React.createElement(AnimatedRow, { xAxis: 'center', bg: theme.palette.background.darker, ph: theme.spacing.page, pv: theme.spacing.standard.sm, style: [yPosStyle, styles.container] },
        React.createElement(ToggleIcon, { status: isVisible ? 'visible' : 'hidden', animationDuration: ANIMATION_DURATION, onToggle: toggleVisible }),
        React.createElement(FlatList, { horizontal: true, ref: flatlistRef, data: images, renderItem: ({ item, index: i }) => (React.createElement(ImageThumbnail, { src: item.uri, bcl: i === index
                    ? theme.palette.primary.main
                    : theme.palette.border.main, onPress: () => onThumbnailPress(i) })), getItemLayout: (_, index) => ({
                index,
                length: THUMBNAIL_SIZE,
                offset: index * (THUMBNAIL_SIZE + theme.spacing.standard.xs),
            }), ItemSeparatorComponent: () => React.createElement(Row, { style: { width: theme.spacing.standard.xs } }) })));
};
const stylesGenerator = createThemedStyle(({}) => ({
    container: Platform.select({
        web: {
            boxShadow: 'rgba(0, 0, 0, 0.3) 0 -2px 10px 0',
        },
        default: {},
    })
}));
export default ImageThumbnailList;
