import React from 'react';
import { ScrollView } from 'react-native';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import Row from 'src/components/10-atoms/layout/Row';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import styleUtils from 'src/utils/styles';
import AnsweredFilter from './AnsweredFilter';
import SortBy from './SortBy';
import Divider from 'src/components/10-atoms/Divider';
import QPDateFilter from './QPDateFilter';
const QuestionBankFilters = ({ filters, onChange }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(Row, { bbw: 1, bg: theme.palette.background.dark },
        React.createElement(ScrollView, { horizontal: true, contentContainerStyle: [styleUtils.flex] },
            React.createElement(Row, { ph: theme.spacing.page, pv: theme.spacing.standard.xs, spacing: theme.spacing.standard.xs, yAxis: 'center' },
                React.createElement(SortBy, { value: filters.sortBy, onChange: sortBy => onChange({ ...filters, sortBy }) }),
                React.createElement(Divider, { orientation: 'vertical' }),
                React.createElement(FeatherIcon, { name: "filter" }),
                React.createElement(AnsweredFilter, { value: filters.answered, onChange: answered => onChange({ ...filters, answered }) }),
                React.createElement(QPDateFilter, { value: filters.qpStartDate, onChange: qpStartDate => onChange({ ...filters, qpStartDate }) })))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QuestionBankFilters;
