import { gql } from '@apollo/client';
import useGQLQuery from 'src/lib/graphql/hooks/useGQLQuery';
const SUBJECT_LIST_QUERY = gql `
  query EP_SubjectList(
    $examLevelID: ID!
    $pagination: PaginationOptions!
    $type: Int!
  ) {
    ep_subjectList(
      pagination: $pagination
      examLevelID: $examLevelID
      type: $type
    ) {
      totalCount
      list {
        id
        data {
          title
          description
        }
        type
        attributes {
          totalQuestionCount
          answeredQuestionCount
        }
      }
    }
  }
`;
export function useSubjectListQuery(examLevelID, type, options) {
    return useGQLQuery(SUBJECT_LIST_QUERY, {
        variables: {
            pagination: {
                offset: 0,
                limit: 50,
            },
            examLevelID,
            type,
        },
        lazy: true,
        ...options,
    });
}
