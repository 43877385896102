import React from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import FeatherIcon from '../icons/FeatherIcon';
import FilterChipLayout from './FilterChipLayout';
const DropDownFilterChipLayout = ({ children, ...filterChipProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(FilterChipLayout, { xAxis: 'space-between', spacing: theme.spacing.standard.xs, ...filterChipProps },
        children,
        React.createElement(FeatherIcon, { name: "chevron-down", size: theme.iconSize.xs })));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default DropDownFilterChipLayout;
