import { useEffect, useMemo, useState } from "react";
import { useEPQuestionSearchQuery } from "src/modules/exam-preparation/graphql/question/questionSearch";
import { useNavigation, useRoute } from "@react-navigation/native";
import useSearch from "src/hooks/useSearch";
import { QuestionSearchSortBy } from "src/modules/exam-preparation/graphql/question/types";
import { getDateAsUTC } from "src/utils/date";
import ms from 'ms';
// import useEffectWithCtx from "src/hooks/useEffectUtils/useEffectWithCtx";
export default function useData(subjectID) {
    const [listQueryState, listQueryActions] = useEPQuestionSearchQuery();
    const route = useRoute();
    const [filters, setFilters] = useState({
        answered: route.params.filters?.answered,
        sortBy: route.params.filters?.sortBy ?? QuestionSearchSortBy.TOPICS,
        qpStartDate: route.params.filters?.qpStartDate ?? undefined
    });
    const [searchState, searchActions] = useSearch('debounce', 300, route.params.filters?.query ?? '');
    // When search query is input, then automatically change the 
    // sortBy to RELEVANCE, likewise when the search query is removed,
    // automatically change the sortBy to TOPIC
    // useEffectWithCtx(() => {
    //   if (searchState.deferredVal.trim().length > 0) {
    //     if (filters.sortBy !== QuestionSearchSortBy.RELEVANCE) {
    //       setFilters({
    //         ...filters,
    //         sortBy: QuestionSearchSortBy.RELEVANCE,
    //       })
    //     }
    //   } else {
    //     if (filters.sortBy !== QuestionSearchSortBy.TOPICS) {
    //       setFilters({
    //         ...filters,
    //         sortBy: QuestionSearchSortBy.TOPICS,
    //       })
    //     }
    //   }
    // }, {}, [searchState.deferredVal])
    const navigation = useNavigation();
    useEffect(() => {
        navigation.setParams({
            filters: {
                ...filters,
                query: searchState.deferredVal,
            },
        });
    }, [filters, searchState.deferredVal]);
    const listQueryVars = useMemo(() => {
        return {
            query: searchState.deferredVal,
            ancestorID: subjectID,
            withAncestorsDetails: false,
            answered: filters.answered,
            sortBy: filters.sortBy,
            qpStartDate: getQPStartDate(filters.qpStartDate),
        };
    }, [filters, searchState.deferredVal]);
    const state = {
        listQuery: listQueryState,
        listQueryVars,
        filters,
        search: searchState,
    };
    const actions = {
        listQuery: listQueryActions,
        setFilters,
        search: searchActions,
    };
    return [state, actions];
}
function getQPStartDate(value) {
    if (!value)
        return undefined;
    return getDateAsUTC(new Date(Date.now() - ms(value)));
}
