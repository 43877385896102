import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Row from '../layout/Row';
import withPress from 'src/hoc/withPress';
const FilterChipLayout = ({ style, ...rowProps }) => {
    const [{ theme, styles }] = useTheme(stylesGenerator);
    return (React.createElement(Row, { bwd: 1, bg: theme.palette.background.main, brd: theme.pressableSize.sm / 2, style: [styles.container, style], ph: theme.spacing.standard.sm, pv: theme.spacing.standard.xxs, yAxis: 'center', ...rowProps }));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        minWidth: theme.pressableSize.sm,
        minHeight: theme.pressableSize.sm,
    },
}));
export default withPress(FilterChipLayout);
