import { renderMathInElement } from 'mathlive';
import Embed from 'quill/blots/embed';
export class MathFieldEditor extends Embed {
    static tagName = 'math-field';
    static blotName = 'math-field';
    static create(value) {
        const mf = super.create();
        mf.addEventListener('mount', () => {
            this.addPresets(mf);
            this.addStyles(mf);
            this.addListeners(mf);
        });
        this.updateExpr(mf, value.expr);
        return mf;
    }
    static value(mf) {
        return {
            expr: mf.value || mf.innerText,
        };
    }
    static formats(mf) {
        return this.value(mf);
    }
    format(name, value) {
        if (name === 'expr' && value) {
            MathFieldEditor.updateExpr(this.domNode, value);
        }
        else {
            super.format(name, value);
        }
    }
    formats() {
        const formats = super.formats();
        formats[MathFieldEditor.blotName] = MathFieldEditor.formats(this.domNode);
        return formats;
    }
    html() {
        const value = MathFieldEditor.value(this.domNode);
        return `$$${value.expr
        // .replace(/\s/g, '')
        }$$`;
    }
    static updateExpr(mf, value) {
        setTimeout(() => {
            mf.executeCommand([
                'insert',
                value,
                {
                    insertionMode: 'replaceAll',
                    format: 'latex',
                },
            ]);
        }, 10);
    }
    static addPresets(mf) {
        // mf.setAttribute('smart-mode', 'true');
        mf.mathModeSpace = '\\:';
        // mf.keybindings = [];
        // mf.inlineShortcuts = {};
    }
    static addStyles(mf) {
        mf.style.width = 'calc(100% - 1em)';
        mf.style.margin = '4px 0px';
        mf.style.fontSize = '1.2em';
    }
    static addListeners(mf) {
        mf.addEventListener('focusin', function () {
            this.dispatchEvent(new CustomEvent('mf-focus', {
                bubbles: true,
            }));
        });
        mf.addEventListener('focusout', function () {
            this.dispatchEvent(new CustomEvent('mf-blur', {
                bubbles: true,
            }));
        });
        // mf.addEventListener(
        //   'keydown',
        //   (ev: any) => {
        //     switch (ev.key) {
        //       case 'Backspace': {
        //         ev.preventDefault();
        //         (mf as any).executeCommand(['deleteBackward']);
        //         break;
        //       }
        //       case 'ArrowLeft': {
        //         ev.preventDefault();
        //         (mf as any).executeCommand(['moveToPreviousChar']);
        //         break;
        //       }
        //       case 'ArrowRight': {
        //         ev.preventDefault();
        //         (mf as any).executeCommand(['moveToNextChar']);
        //         break;
        //       }
        //       case 'ArrowDown': {
        //         ev.preventDefault();
        //         (mf as any).executeCommand(['moveDown']);
        //         break;
        //       }
        //       case 'ArrowUp': {
        //         ev.preventDefault();
        //         (mf as any).executeCommand(['moveUp']);
        //         break;
        //       }
        //     }
        //   },
        //   {capture: true},
        // );
    }
}
export class MathFieldRenderer extends Embed {
    static blotName = 'math-field-renderer';
    static className = 'math-field-renderer';
    static tagName = 'DIV';
    static create(value) {
        const node = super.create();
        node.style.width = '100%';
        setTimeout(() => {
            node.innerHTML = value.expr;
            renderMathInElement(node);
        }, 100);
        return node;
    }
    static value(mf) {
        return {
            expr: mf.value || mf.innerText,
        };
    }
    static formats(mf) {
        return this.value(mf);
    }
}
