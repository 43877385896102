import React from 'react';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import Typography from 'src/components/10-atoms/typography/Typography';
import { PRESSABLE_SIZE } from 'src/components/templates/PressableArea';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import withPress from 'src/hoc/withPress';
import CircularProgressBar from 'src/components/10-atoms/progress-bar/CircularProgressBar';
const SubjectItem = ({ subject, style, ...rowProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const answerCompletion = subject.attributes.totalQuestionCount > 0
        ? Math.floor((subject.attributes.answeredQuestionCount / subject.attributes.totalQuestionCount) * 100)
        : 0;
    return (React.createElement(Row, { xAxis: "space-between", yAxis: "center", spacing: 24, bg: theme.palette.background.dark, style: [styles.container, style], ...rowProps },
        React.createElement(Column, { yAxis: "center", flex: true },
            React.createElement(Typography, { numberOfLines: 3, ellipsizeMode: "tail" }, subject.data.title),
            !!subject.data.description && (React.createElement(Typography, { flex: true, numberOfLines: 3, ellipsizeMode: "tail", variant: TypographyVariant.CAPTION, color: theme.palette.typography.secondary }, subject.data.description))),
        React.createElement(Row, { yAxis: "center", spacing: theme.spacing.standard.xxs },
            React.createElement(CircularProgressBar, { size: theme.iconSize.lg, value: answerCompletion }),
            React.createElement(FeatherIcon, { name: "chevron-right", color: theme.palette.primary.main }))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        minHeight: PRESSABLE_SIZE,
    },
}));
export default withPress(SubjectItem);
